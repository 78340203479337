import { UNLIMITED_LICENSES } from "../../../utilis/shared";

export const updateLicenseCountValue = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: number) => void,
) => {
  e.target.checked
    ? setFieldValue("license_count", UNLIMITED_LICENSES)
    : setFieldValue("license_count", null);
};

import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../hooks/useStoreHook";
import { Service } from "../../interfaces/Service";
import { useState } from "react";
import {
  SERVICE_COLOR_TOOLTIP,
  UPLOAD_SERVICE_IMAGE_NOTE,
} from "../../utilis/shared";

interface AddServiceFormProps {
  handleSubmit: (values: Omit<Service, "uuid">) => void;
}

export default function AddServiceForm({ handleSubmit }: AddServiceFormProps) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const initialValue: Omit<Service, "uuid"> = {
    name: "",
    description: "",
    color: "#000",
    image: null,
  };

  const schema: yup.SchemaOf<Omit<Service, "uuid">> = yup.object().shape({
    name: yup.string().required("Required"),
    description: yup.string().required("Required"),
    color: yup.string(),
    image: yup
      .mixed()
      .required("Required")
      .test(
        "fileType",
        "Only image files are allowed",
        (value) => value && value.type.includes("image"),
      ),
  });

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="name"
            label="Name*"
            fullWidth
            margin="normal"
            name="name"
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            data-testid="service_name"
            size="small"
          />
          <TextField
            type="text"
            id="description"
            label="Description*"
            fullWidth
            margin="normal"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            error={Boolean(errors.description && touched.description)}
            helperText={
              errors.description && touched.description && errors.description
            }
            data-testid="service_description"
            size="small"
            multiline
            rows={5}
          />

          <Box mt={2}>
            {imagePreview && (
              <Box mt={2}>
                <img
                  src={imagePreview}
                  alt="preview"
                  style={{ height: "150px" }}
                />
              </Box>
            )}

            <Box mb={1}>
              <Box display={"flex"} alignItems={"center"} mt={2}>
                <Button
                  variant="outlined"
                  color={touched.image && errors.image ? "error" : "primary"}
                  component="label"
                >
                  Upload background image
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    id="image-upload"
                    onChange={(event) => {
                      handleImageChange(event);
                      console.log(event.target.files[0]);
                      setFieldValue("image", event.target.files[0]);
                    }}
                  />
                </Button>

                {values.file ? (
                  <Typography variant="body2" ml={2} mb={0}>
                    {values.file.name}
                  </Typography>
                ) : null}
              </Box>

              {touched.image && errors.image ? (
                <Typography
                  sx={{ fontSize: "0.75rem", ml: 2, mt: 1 }}
                  color="error"
                >
                  {errors.image}
                </Typography>
              ) : null}
              <small>{UPLOAD_SERVICE_IMAGE_NOTE}</small>
            </Box>
          </Box>
          <Box mt={2}>
            <Tooltip placement="top" title={SERVICE_COLOR_TOOLTIP}>
              <label>Color</label>
            </Tooltip>
            <input
              type="color"
              value={values.color || ""}
              name="color"
              onChange={handleChange}
              style={{ marginLeft: "10px" }}
            />
          </Box>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                data-testid={"submit-btn"}
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate("/services")}
                color="error"
                sx={{ width: "100%" }}
                data-testid={"cancel-btn"}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

import { useNavigate } from "react-router-dom";
import { Field, FieldArray, Formik, getIn } from "formik";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../hooks/useStoreHook";
import { TierPayload, TierResponse } from "../../interfaces/Tier";
import MultiSelect from "../Controls/MultiSelect";
import MUISelect from "../Controls/Select";
import { Consumable } from "../../interfaces/Consumable";
import { Feature } from "../../interfaces/Features";
import DeleteIcon from "@mui/icons-material/Delete";
import { CONSUMABLES_RESET_NOTE, stringifyAmountNumber, UNLIMITED_LICENSES } from "../../utilis/shared";
import { Company } from "../../interfaces/Company";
import PricesForm from "./PricesForm";
import { Country } from "../../interfaces/Address";
import { preventNonNumericValues } from "../../utilis/PreventNonNumericValues";
import { schema } from "./Utlis/schemas";
import { updateLicenseCountValue } from "./Utlis/updateLicenseCountValue";

interface EditTierFormProps {
  handleSubmit: (values: TierPayload) => void;
  tier: TierResponse;
  consumables: Consumable[];
  features: Feature[];
  companies: Company[];
  countries: Country[];
}

export default function EditTierForm({
  handleSubmit,
  tier,
  consumables,
  features,
  companies,
  countries,
}: Readonly<EditTierFormProps>) {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const navigate = useNavigate();

  const initialValue: TierPayload = {
    name: tier?.name || "",
    default_price: {
      unit_amount: tier?.default_price.unit_amount / 100 || 0,
      currency: tier?.default_price.currency || "",
      interval: tier?.default_price.interval || "",
      unit_price_overrides:
        tier?.default_price?.unit_price_overrides.map((override: any) => {
          const amount = override.unit_price?.amount / 100;
          return {
            ...override,
            unit_price: {
              ...override.unit_price,
              amount,
            },
          };
        }) || [],
    },
    license_count: tier?.license_count || 0,
    unlimited_licences: tier?.license_count === UNLIMITED_LICENSES,
    description: tier?.description || "",
    features: tier?.features?.map((feature) => feature.uuid) || [],
    consumables:
      tier?.consumables.length > 0
        ? tier?.consumables
        : [
            {
              uuid: "",
              amount: 0,
            },
          ],
    custom_tier: tier?.companies?.length > 0,
    companies: tier?.companies || [],
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={schema}
      onSubmit={(values: TierPayload) =>
        handleSubmit({
          ...values,
          features: values?.features.filter((feature) => feature !== null),
          default_price: {
            ...values.default_price,
            unit_amount: values.default_price.unit_amount * 100,
            unit_price_overrides: values.default_price.unit_price_overrides.map(
              (unit_price_override: any) => {
                return {
                  ...unit_price_override,
                  unit_price: {
                    ...unit_price_override.unit_price,
                    amount: stringifyAmountNumber(
                      unit_price_override.unit_price.amount,
                    ),
                  },
                };
              },
            ),
          },
        })
      }
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="name"
            label="Name*"
            fullWidth
            margin="normal"
            name="name"
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            data-testid="tier_name"
            size="small"
          />
          <TextField
            type="text"
            id="description"
            label="Description*"
            fullWidth
            margin="normal"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            error={Boolean(errors.description && touched.description)}
            helperText={
              errors.description && touched.description && errors.description
            }
            data-testid="tier_description"
            size="small"
            multiline
            rows={5}
          />
          <TextField
            type={values.unlimited_licences ? "text" : "number"}
            id="license_count"
            label="License count*"
            fullWidth
            margin="normal"
            name="license_count"
            disabled={Boolean(values.unlimited_licences)}
            error={
              values.unlimited_licences === false
                ? Boolean(errors.license_count && touched.license_count)
                : null
            }
            helperText={
              !values.unlimited_licences &&
              errors.license_count &&
              touched.license_count &&
              errors.license_count
            }
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={preventNonNumericValues}
            value={
              values.unlimited_licences ? "Unlimited" : values.license_count
            }
            data-testid="license_count"
            size="small"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="unlimited_licences"
                data-testid="unlimited_licences"
                onChange={(e) => {
                  handleChange(e);
                  updateLicenseCountValue(e, setFieldValue);
                }}
                onBlur={handleBlur}
                checked={values.unlimited_licences}
              />
            }
            label="Unlimited licences"
          />
          <Field name="features">
            {({ form, field, meta }) => (
              <MultiSelect
                label="Features"
                dataTestid={"features"}
                meta={meta}
                form={form}
                field={field}
                list={features}
                uuid="uuid"
                value="name"
                sx={{ mt: 2 }}
              />
            )}
          </Field>
          <FieldArray name="consumables">
            {({ push, remove }) => {
              return values?.consumables.map((item, index) => {
                const consumable_uuid = `consumables[${index}].uuid`;
                const consumable_amount = `consumables[${index}].amount`;
                const consumable_uuid_error = getIn(errors, consumable_uuid);
                const consumable_amount_error = getIn(
                  errors,
                  consumable_amount,
                );
                return (
                  <Grid
                    container
                    alignItems={"start"}
                    spacing={2}
                    sx={{ pt: 2, pb: 1 }}
                    key={index}
                  >
                    <Grid item xs={5}>
                      <MUISelect
                        value={item.uuid}
                        label={"Consumable"}
                        dataTestid={"consumable"}
                        name={`consumables[${index}].uuid`}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        keyItem="uuid"
                        keyValue="name"
                        list={consumables}
                        labelId={"label-consumable"}
                        id={"select-consumable"}
                        error={consumable_uuid_error}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        type="number"
                        id="consumable_amount"
                        label="Amount"
                        fullWidth
                        name={`consumables[${index}].amount`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={preventNonNumericValues}
                        value={item.amount || ""}
                        size="small"
                        error={Boolean(consumable_amount_error)}
                        helperText={consumable_amount_error}
                      />
                    </Grid>
                    <Grid item xs={2} display={"flex"} alignItems={"center"}>
                      {values.consumables.length > 1 && (
                        <IconButton
                          size="small"
                          data-testid="deleteSettings"
                          aria-label="delete"
                          sx={{ mt: "0.3em" }}
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                      {index === values.consumables.length - 1 && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => push({ uuid: "", amount: "" })}
                        >
                          Add
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                );
              });
            }}
          </FieldArray>
          <small> Note: {CONSUMABLES_RESET_NOTE}</small>
          <Box component={"h4"} mb={0}>
            Price
          </Box>
          <small> Note: this price will be the default for any purchase.</small>
          <Grid
            container
            spacing={2}
            alignItems={"flex-start"}
            sx={{ pt: 2, pb: 1 }}
          >
            <Grid item xs={4}>
              <MUISelect
                error={
                  errors?.default_price?.currency &&
                  touched?.default_price?.currency &&
                  errors?.default_price?.currency
                }
                value={values?.default_price?.currency.toUpperCase()}
                label={"Currency"}
                dataTestid={"currency"}
                required
                name="default_price.currency"
                handleChange={handleChange}
                handleBlur={handleBlur}
                list={countries?.map((country) => country.currency)}
                labelId={"label-currency"}
                id={"select-currency"}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="number"
                id="unit_amount"
                label="Unit Amount*"
                fullWidth
                name="default_price.unit_amount"
                error={Boolean(
                  errors?.default_price?.unit_amount &&
                    touched?.default_price?.unit_amount,
                )}
                helperText={
                  Boolean(
                    errors?.default_price?.unit_amount &&
                      touched?.default_price?.unit_amount,
                  ) && errors?.default_price?.unit_amount
                }
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={preventNonNumericValues}
                value={values?.default_price.unit_amount}
                size="small"
              />
            </Grid>

            <Grid item xs={4}>
              <MUISelect
                error={
                  errors?.default_price?.interval &&
                  touched?.default_price?.interval &&
                  errors?.default_price?.interval
                }
                value={values.default_price?.interval}
                label={"Interval"}
                dataTestid={"intrval"}
                required
                name="default_price.interval"
                handleChange={handleChange}
                handleBlur={handleBlur}
                list={["month", "year"]}
                labelId={"label-interval"}
                id={"select-interval"}
              />
            </Grid>
          </Grid>
          <Box component={"h4"} my={1}>
            Additional Currency options
          </Box>
          <small>
            {" "}
            Note: Any additional currencies will be added to help the customer
            pay in his local currency.
          </small>
          <PricesForm
            values={values}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            countries={countries}
            setFieldValue={setFieldValue}
            touched={touched}
          />

          <Box component={"h4"} mb={0}>
            Custom Tier
          </Box>
          <small>
            {" "}
            Note: Checking this box will hide this tier from the tiers select
            screen and will only be visible to the companies that it is attached
            to.
          </small>
          <FormControlLabel
            control={
              <Checkbox
                name="custom_tier"
                data-testid="custom_tier"
                onChange={(e) => {
                  handleChange(e);
                  if (!e.target.checked) {
                    setFieldValue("companies", []);
                    setFieldTouched("companies", false, false);
                  }
                }}
                onBlur={handleBlur}
                checked={values.custom_tier}
              />
            }
            sx={{ display: "block" }}
            label="Custom tier"
          />

          {values.custom_tier && (
            <Field name="companies">
              {({ form, field, meta }: any) => (
                <MultiSelect
                  label="Companies"
                  dataTestid={"companies"}
                  meta={meta}
                  form={form}
                  field={field}
                  list={companies}
                  uuid="uuid"
                  value="name"
                  sx={{ mt: 2 }}
                />
              )}
            </Field>
          )}
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                data-testid={"submit-btn"}
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
                data-testid={"cancel-btn"}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

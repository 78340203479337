import * as yup from "yup";
import { ConsumableTierPayload, DefaultPrice } from "../../../interfaces/Tier";

export const currencyOptionItemSchema = yup.object().shape({
  currency: yup.string(),
  unit_amount: yup.number().when("currency", {
    is: (currency) => !!currency,
    then: yup.number().required("Required").min(0, "Must be positive"),
    otherwise: yup.number(),
  }),
});

export const unitPriceOverridesSchema = yup.object().shape({
  country_codes: yup.array().of(yup.string()),
  unit_price: yup.object().shape({
    amount: yup.number().required("Required").positive("Must be positive"),
    currency_code: yup.string().required("Required"),
  }),
});

export const defaultPriceSchema = (requiredInterval?: boolean) => {
  return yup.object().shape({
    unit_amount: yup
      .number()
      .required("Required")
      .typeError("Required")
      .min(0, "Must be positive"),
    currency: yup.string().required("Required"),
    interval: requiredInterval
      ? yup.string().required("Required").oneOf(["month", "year"])
      : yup.string().nullable(),
    currency_options: yup.array().of(currencyOptionItemSchema).nullable(),
    unit_price_overrides: yup.array().of(unitPriceOverridesSchema),
  });
};

const consumablesSchema: yup.SchemaOf<ConsumableTierPayload> = yup
  .object()
  .shape({
    uuid: yup
      .string()
      .nullable()
      .test("uuid-required-if-amount", "Required", function (value) {
        const { amount } = this.parent;
        return !amount || (amount && !!value);
      }),
    amount: yup
      .number()
      .nullable()
      .test("amount-required-if-uuid", "Required", function (value) {
        const { uuid } = this.parent;
        return !uuid || (uuid && value !== null && value !== undefined);
      })
      .when("uuid", {
        is: (uuid: string | null) => !!uuid,
        then: yup
          .number()
          .typeError("Required")
          .positive("Must be positive")
          .min(1, "Must be greater than or equal to 1"),
        otherwise: yup.number().nullable(),
      }),
  });

const licenseCountSchema: yup.SchemaOf<number> = yup
  .number()
  .when("unlimited_licences", {
    is: false,
    then: yup
      .number()
      .typeError("Required")
      .required("Required")
      .integer("Amount must be an integer")
      .min(1, "Must be greater than or equal 1"),
    otherwise: yup.number().nullable(),
  });

const companiesSchema = yup
  .array()
  .of(yup.string().required("Required"))
  .when("custom_tier", {
    is: true,
    then: yup
      .array()
      .of(yup.string().required("Required"))
      .min(1, "At least one company should be selected")
      .required("Required"),
    otherwise: yup.array().notRequired(),
  });

export const schema: yup.SchemaOf<any> = yup.object().shape({
  name: yup.string().required("Required"),
  description: yup.string().required("Required"),
  license_count: licenseCountSchema,
  unlimited_licences: yup.boolean(),
  features: yup.array(yup.string()).nullable(),
  consumables: yup.array().of(consumablesSchema),
  default_price: defaultPriceSchema(true).required(),
  custom_tier: yup.boolean().required(),
  companies: companiesSchema,
});

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getServices } from "../../apis/v1/Services/Services";
import { Service } from "../../interfaces/Service";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import ServicesListTable from "../../components/Services/ServicesListTable";
import PoliciesListCard from "../../components/Policies/PoliciesListCard";
import ReasonsListCard from "../../components/Reasons/ReasonsListCard";

export default function ServicesList() {
  const navigate = useNavigate();
  const [services, setServices] = useState<Service[]>([]);
  useEffect(() => {
    getServices().then((res) => setServices(res.data));
  }, []);

  return (
    <>
      <Card variant="outlined">
        <CardHeader title="Services" subheader="List of services, only services that have tiers will appear in central platform " />
        <CardContent>
          <Button variant={"outlined"} onClick={() => navigate("/service/add")}>
            Add
          </Button>
        </CardContent>
        <CardContent>
          <ServicesListTable services={services} setServices={setServices} />
        </CardContent>
      </Card>
      <PoliciesListCard />
      <ReasonsListCard />
    </>
  );
}

import { Box, Grid } from "@mui/material";

export default function ConsumableAddonsDetailsCard({ consumableAddon }: { consumableAddon: any }) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="consumable-details" item xs={12} sm={12} md={12}>
        <Box component={"p"} my={0}>
          Name: {consumableAddon.consumable.name}
        </Box>
        <Box component={"p"} my={0}>
          Amount: {consumableAddon.amount}
        </Box>
        <Box component={"p"} my={0}>
          Default Price: {consumableAddon.default_price.unit_amount / 100}{" "}
          {consumableAddon.default_price.currency.toUpperCase()}
        </Box>
        <Box my={0}>
          Additional price overrides:{" "}
          <Box component={"ul"} my={0}>
            {consumableAddon.default_price.unit_price_overrides.map((option) => (
              <li key={option.unit_price.currency_code}>
                {option.unit_price.amount / 100} {option.unit_price.currency_code}
              </li>
            ))}
          </Box>
        </Box>
        <Box component={"p"} mt={0}>
          Tier: {consumableAddon.tier.name}
        </Box>
      </Grid>
    </Grid>
  );
}

import { Button, Grid, IconButton, TextField } from "@mui/material";
import { FieldArray } from "formik";
import MUISelect from "../Controls/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import { preventNonNumericValues } from "../../utilis/PreventNonNumericValues";

export default function PricesForm({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  countries,
  setFieldValue,
}) {
  return (
    <FieldArray name="default_price.unit_price_overrides">
      {({ push, remove }) => {
        return (
          <>
            <Button
              variant="outlined"
              color="primary"
              sx={{ my: 1, display: "block" }}
              onClick={() =>
                push({
                  country_codes: [],
                  unit_price: { amount: "", currency_code: "" },
                })
              }
            >
              Add Currency Option
            </Button>
            {values?.default_price?.unit_price_overrides.map((item, index) => {
              const currency_option_country_codes = `default_price.unit_price_overrides[${index}].country_codes`;
              return (
                <Grid
                  container
                  spacing={2}
                  sx={{ pt: 2, pb: 1 }}
                  key={index}
                  alignItems={"start"}
                >
                  <Grid item xs={5}>
                    <MUISelect
                      label={"Currency"}
                      name={`default_price.unit_price_overrides[${index}].unit_price.currency_code`}
                      value={item.unit_price.currency_code}
                      dataTestid={"currency_option_currency"}
                      handleChange={(e) => {
                        handleChange(e);
                        const country_code = countries.find(
                          (country) => country.currency === e.target.value,
                        ).code;
                        setFieldValue(currency_option_country_codes, [
                          country_code,
                        ]);
                      }}
                      handleBlur={handleBlur}
                      list={countries?.map((country) => country.currency)}
                      labelId={"currency_option_currency"}
                      id={"currency_option_currency"}
                      error={
                        touched?.default_price?.unit_price_overrides?.[index]
                          ?.unit_price.currency_code &&
                        errors?.default_price?.unit_price_overrides?.[index]
                          ?.unit_price.currency_code
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      type="number"
                      id="currency_option_unit_amount"
                      label="Amount"
                      fullWidth
                      name={`default_price.unit_price_overrides[${index}].unit_price.amount`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={item.unit_price.amount}
                      size="small"
                      error={
                        touched?.default_price?.unit_price_overrides?.[index]
                          ?.unit_price?.amount &&
                        Boolean(
                          errors?.default_price?.unit_price_overrides?.[index]
                            ?.unit_price?.amount,
                        )
                      }
                      helperText={
                        touched?.default_price?.unit_price_overrides?.[index]
                          ?.unit_price?.amount &&
                        errors?.default_price?.unit_price_overrides?.[index]
                          ?.unit_price?.amount
                      }
                      onKeyDown={preventNonNumericValues}
                    />
                  </Grid>
                  <Grid item xs={2} display={"flex"} alignItems={"center"}>
                    {(index > 0 ||
                      values.default_price.unit_price_overrides.length > 0) && (
                      <IconButton
                        size="small"
                        data-testid="deleteSettings"
                        aria-label="delete"
                        sx={{ mt: "0.3em" }}
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </>
        );
      }}
    </FieldArray>
  );
}

import { Box, Grid } from "@mui/material";
import { TierResponse } from "../../interfaces/Tier";
import {
  CONSUMABLES_RESET_NOTE,
  UNLIMITED_LICENSES,
} from "../../utilis/shared";
import { Company } from "../../interfaces/Company";

export default function TierDetailsCard({
  tier,
  companies,
}: Readonly<{ tier: TierResponse; companies: Company[] }>) {
  const getCompany = (company_uuid) => {
    return companies?.find((company) => company.uuid === company_uuid);
  };

  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="company-details" item xs={12} sm={12} md={12}>
        <Box component={"p"} my={0}>
          Name: {tier.name}
        </Box>
        <Box component={"p"} my={0}>
          Description: {tier.description}
        </Box>
        <Box component={"p"} my={0}>
          License count:{" "}
          {tier.license_count === UNLIMITED_LICENSES
            ? "Unlimited"
            : tier.license_count}
        </Box>
        <Box component={"p"} my={0}>
          Hidden: {tier?.hidden ? "Yes" : "No"}
        </Box>
        <Box component={"h4"} my={1}>
          Features:
        </Box>
        <Box my={0}>
          {tier?.features?.length === 0 && <Box>-</Box>}
          <Box component={"ul"} my={0}>
            {tier.features.map((feature) => (
              <li key={feature.name}>{feature.name}</li>
            ))}
          </Box>
        </Box>
        <Box component={"h4"} mt={1} mb={0}>
        Consumables:
        </Box>
        <small> Note: {CONSUMABLES_RESET_NOTE}</small>
        <Box my={1}>
          {tier?.consumables?.length === 0 && <Box>-</Box>}
          <Box component={"ul"} my={0}>
            {tier?.consumables.map((consumable) => (
              <li key={consumable.name}>
                Name: {consumable.name} , Amount: {consumable.amount}
              </li>
            ))}
          </Box>
        </Box>
        <Box component={"h4"} my={1}>
          Price
        </Box>
        <Box component={"p"} my={0}>
          Default Price: {tier.default_price.unit_amount / 100}{" "}
          {tier.default_price.currency} / {tier.default_price.interval}
        </Box>
        <Box my={0}>
          Additional price overrides:{" "}
          <Box component={"ul"} my={0}>
            {tier.default_price.unit_price_overrides.map((option: any) => {
              return (
                <li key={option.unit_price.currency_code}>
                  {option.unit_price.amount / 100}{" "}
                  {option.unit_price.currency_code}
                </li>
              );
            })}
          </Box>
        </Box>
        <Box component={"h4"} my={1}>
          Custom tier
        </Box>
        <Box mb={2}>
          Companies:
          {tier?.companies?.length === 0 && <Box>-</Box>}
          <Box component={"ul"} my={0}>
            {tier.companies?.map((company_uuid: string) => (
              <li key={company_uuid}>{getCompany(company_uuid)?.name}</li>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

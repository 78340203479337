/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { getConsumableAddonsDetails } from "../../apis/v1/Services/ConsumablesAddons";
import ConsumableAddonsDetailsCard from "../../components/ConsumablesAddons/ConsumableAddonsDetailsCard";
import { ADDONS_CONSUMABLES_RESET_NOTE } from "../../utilis/shared";

export default function ConsumableAddonsDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const [consumableAddon, setConsumableAddon] = useState<any>();

  useEffect(() => {
    getConsumableAddonsDetails(
      params.service_uuid,
      params.consumable_uuid,
      params.consumable_addon_uuid,
    ).then((res) => setConsumableAddon(res.data));
  }, []);

  return (
    <>
      {consumableAddon && (
        <Card variant="outlined">
          <CardHeader
            title="Consumable Addon Details"
            subheader={`Details about this consumable addon, ${ADDONS_CONSUMABLES_RESET_NOTE}`}
          />
          <CardContent>
            <Button
              variant={"outlined"}
              onClick={() =>
                navigate(
                  `/service/${params.service_uuid}/consumable/${params.consumable_uuid}/consumable_addon/${params.consumable_addon_uuid}/edit`,
                )
              }
            >
              Edit
            </Button>
          </CardContent>
          <CardContent>
            <ConsumableAddonsDetailsCard consumableAddon={consumableAddon} />
          </CardContent>
        </Card>
      )}
    </>
  );
}
